import { useState, useEffect } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { Row, Col, Table, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { SquareLegend } from '../utilities/ui_tools';
import { FullDoughnuthKPI } from './Graph/FullDoughnuth';
import { FormattedValue } from '../common/FormattedValues/FormattedValues';

import {QueryCubeRemote} from '../common/APICalls/apiCalls'

export const StoreCreditByLocation = ({ queryToRender, queryToRenderDetail, key,referenceTotal }) => {

  const [data, setData] = useState()
  const [dataDetail, setDataDetail] = useState()

  const [totalValue, setTotal] = useState(0)
  const [totalValueDetail, setTotalDetail] = useState(0)

  const { resultSet: total } = useCubeQuery(queryToRender);
  const { resultSet: totalDetail} = useCubeQuery(queryToRenderDetail);
  const revenueTotal = QueryCubeRemote(referenceTotal) 
  const[grandTotal,setGrandTotal] = useState(0)

  const { t } = useTranslation();

  useEffect(() => {

    const parseResultSet = (total,revenueTotal) => {
  
      const totalRevenue =Object.keys(revenueTotal.tablePivot()[0]).map(function(key){
        let totalRevenue = revenueTotal.tablePivot()[0][key];
        return  (totalRevenue -parseInt(process.env.REACT_APP_SC_CORRECTION_CENTS))*-1 
      })
  
      setGrandTotal(totalRevenue[0])
  
      if (!total) {
        return
      }

      const columns = total.tableColumns()
      const totalValue = total.tablePivot().reduce((x, r) => x + Number(r[columns[1].key]), 0)
      const difference = (totalRevenue[0]) - totalValue 


      const data = total.tablePivot().reduce((acc, totalRow, i) => {
        const value = totalRow[columns[1].key] / 100
        acc.rows.push(<tr key={key + i}>
          <td className="cell-label grouped-cell" ><SquareLegend indexColor={i} keyStorage={''} />&nbsp;{totalRow[columns[0].key]}</td>
          <td className="cell-value grouped-cell" ><FormattedValue type="money" value={totalRow[columns[0].key] === 'N/A' ? value + (difference/100): value} /></td>
          <td className="cell-value grouped-cell" ><FormattedValue type="decimal" value={Number(
            (totalRow[columns[1].key] / totalValue) * 100)} /></td>
        </tr>)

        acc.values.push(value)
        acc.labels.push(totalRow[columns[0].key] / 100)
                
        return acc
      }, { rows: [], values: [], labels: [], chart: null });

      data.chart = <FullDoughnuthKPI values={data.values} labelsIn={data.labels} />

      setTotal(totalValue / 100)
      return data
    }
    const dataParsedTotal =[(total && revenueTotal)? parseResultSet(total,revenueTotal): []];
    setData(dataParsedTotal[0])

  }, [total,revenueTotal]);

  useEffect(() => {
    const parseResultSet =(totalDetail, revenueTotal) => {

      const totalRevenue =Object.keys(revenueTotal.tablePivot()[0]).map(function(key){
        let totalRevenue = revenueTotal.tablePivot()[0][key];
        return  (totalRevenue -parseInt(process.env.REACT_APP_SC_CORRECTION_CENTS))*-1 
      })
    setGrandTotal(totalRevenue[0])

      if (!totalDetail) {
        return
      }

      const columns = totalDetail.tableColumns()
      const totalValue = total.tablePivot().reduce((x, r) => x + Number(r[columns[1].key]), 0)
      const difference = (totalRevenue[0]) - totalValue 
      const dataDetail = totalDetail.tablePivot().reduce((acc, totalRow, i) => {
        const value = totalRow[columns[1].key] / 100
        if ((totalRow[columns[0].key] !== 'US' && totalRow[columns[0].key] !== '') && acc.rows.length <10){
          console.log(totalRow[columns[0].key]) 
acc.rows.push(<tr key={key + i}>
            <td className="cell-label grouped-cell" ><SquareLegend indexColor={i} keyStorage={''}/>&nbsp;{totalRow[columns[0].key]}</td>
            <td className="cell-value grouped-cell" ><FormattedValue type="money" value={totalRow[columns[0].key] === 'N/A' ? value + (difference/100): value} /></td>
            <td className="cell-value grouped-cell" ><FormattedValue type="decimal" value={Number(
              (totalRow[columns[1].key] / totalValue) * 100)} /></td>
          </tr>)
    
          acc.values.push(value)
          acc.labels.push(totalRow[columns[0].key] / 100)
    
        }
        console.log(revenueTotal)
        return acc
      }, { rows: [], values: [], labels: [], chart: null });

      dataDetail.chart = <FullDoughnuthKPI values={dataDetail.values} labelsIn={dataDetail.labels} />

      setTotalDetail(grandTotal / 100)
      return dataDetail
    }
    const dataParsed =[(totalDetail && revenueTotal)? parseResultSet(totalDetail,revenueTotal): []];
    setDataDetail(dataParsed[0])
  }, [totalDetail,revenueTotal]);


  return data && dataDetail ? <Row md={2} lg={2} className="g-5 car row_kpi">
            <Col className="col_kpi">
              <Card className="card_kpi">
                <Card.Title>{t('KPIs.TITLES.TOTAL')}</Card.Title>
                <Card.Body>
                    <div className='chart-md'>
                      {data.chart}
                    </div>
                  <Table bordered striped>
                    <thead>
                      <tr>
                        <th className='cell-emphasis'></th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.NUMBER')}</th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                      </tr>

                    </thead>
                    <tbody>
                      {data.rows}
                      <tr className="row-summary">
                        <td className="cell-label">{t('TABLES.END_ROW.TOTAL')}</td>
                        <td className="cell-value"><FormattedValue type="money" value={parseInt(grandTotal/100)} /></td>
                        <td className="cell-value"><FormattedValue type="percent" value={100} /></td>
                      </tr>

                    </tbody>
                  </Table>
                  </Card.Body>
              </Card>
            </Col>
            
            <Col className="col_kpi">
            <Card className="card_kpi">
                <Card.Title>{t('KPIs.TITLES.COUNTRIES_NON_US')}</Card.Title>
                <Card.Body>
                    <div className='chart-md'>
                      {dataDetail.chart}
                    </div>
                  <Table bordered striped>
                    <thead>
                      <tr>
                        <th className='cell-emphasis'></th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.NUMBER')}</th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                      </tr>

                    </thead>
                    <tbody>
                      {dataDetail.rows}

                    </tbody>
                  </Table>
                  </Card.Body>

                </Card>
            </Col>
          </Row> : <div>Wait...</div>
}
  ;
