import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { SquareLegend, generateUUID } from './ui_tools';
import { dateUtilities } from './date_formatting'


export const groupValues = (values, totalRef,countryGroup, typeValues, excludeNA, noPercent, keyStorage) => {
    let arrayLabels = [[], []]
    let arrayValues = [[], []]
    let dataTable = [[], []]
    let totalFromQuery = 0
    values.forEach(element => {
        totalFromQuery += parseInt(element[1])
        if (countryGroup) {
            if (arrayValues[0].length === 0) {
                arrayValues[0].push(0, 0)
                arrayLabels[0].push('US', 'Non US')
            }
            if (element[0] === 'US') {
                arrayValues[0][0] = parseInt(element[1]) + arrayValues[0][0]
            } else {
                if (element[0] === 'N/A') {
                    //arrayLabels[0][2] = 'N/A'
                    arrayValues[0][1] = parseInt(element[1]) + arrayValues[0][1]
                }
                else {
                    arrayValues[0][1] = parseInt(element[1]) + arrayValues[0][1]
                }
            }

            if (arrayValues[1].length < 10 && element[0] !== 'US' && element[0] !== 'N/A' && element[0] !== '') {
                arrayLabels[1].push(element[0])
                arrayValues[1].push(parseInt(element[1]))
            }


        } else {
            arrayLabels[0].push(element[0] ?? '')
            arrayValues[0].push(parseInt(element[1]))
        }
    });
    const labels = arrayLabels[0]
    const naPresent = labels.indexOf('N/A')
    if (naPresent === -1 && !excludeNA)  {
        const emptyPresent = labels.indexOf('');
        if(emptyPresent === -1){
            if(parseInt(totalRef) - parseInt(totalFromQuery) > 0){
                arrayLabels[0].push('N/A')
                arrayValues[0].push(parseInt(totalRef) - parseInt(totalFromQuery))
            }
        } else {
            arrayLabels[0][emptyPresent] = 'N/A'
            arrayValues[0][emptyPresent] = parseInt(arrayValues[0][emptyPresent]) + (parseInt(totalRef) - parseInt(totalFromQuery))
            arrayLabels[0].push(arrayLabels[0].splice(emptyPresent, 1)[0])
            arrayValues[0].push(arrayValues[0].splice(emptyPresent, 1)[0])
        }
    }



    dataTable[0] = arrayLabels[0].map((item, index) => {
        return <tr key={generateUUID(16)}>
            <td key={generateUUID(16)} className="cell-label grouped-cell" ><SquareLegend indexColor={index} keyStorage={keyStorage} />&nbsp;{item}</td>
            <td key={generateUUID(16)} className="cell-value grouped-cell" ><FormattedValue type={typeValues === 'money' ? "money" : "absolut"} value={Number(arrayValues[0][index])} /></td>
            {noPercent ? <></>:<td key={generateUUID(16)} className="cell-value grouped-cell" ><FormattedValue type="percent" value={Number((arrayValues[0][index] / totalRef) * 100)} /></td>}
        </tr>
    })
    dataTable[1] = arrayLabels[1].map((item, index) => {
        return <tr>
            <td key={generateUUID(16)} className="cell-label grouped-cell" ><SquareLegend indexColor={index} keyStorage={''}/>&nbsp;{item}</td>
            <td key={generateUUID(16)} className="cell-value grouped-cell" ><FormattedValue type={typeValues === 'money' ? "money" : "absolut"} value={Number(arrayValues[1][index])} /></td>
            {noPercent ? <></>:<td key={generateUUID(16)} className="cell-value grouped-cell" ><FormattedValue type="percent" value={Number(
                (arrayValues[1][index] / totalRef) * 100)} /></td>}
        </tr>
    })

    return [arrayLabels, arrayValues, dataTable]

}

export const groupValuesExtraFields = (values, totalRef,countryGroup, typeValues, excludeNA, extraFields, noPErcent) => {
  let arrayLabels = [[], []]
  let arrayValues = [[], []]
  let dataTable = [[], []]
  let totalFromQuery = 0
  values.forEach(element => {
      totalFromQuery += parseInt(element[1])
      if (countryGroup) {
          if (arrayValues[0].length === 0) {
              arrayValues[0].push(0, 0)
              arrayLabels[0].push('US', 'Non US')
          }
          if (element[0] === 'US') {
              arrayValues[0][0] = parseInt(element[1]) + arrayValues[0][0]
          } else {
              if (element[0] === 'N/A') {
                  arrayLabels[0][2] = 'N/A'
                  arrayValues[0][2] = parseInt(element[1]) + arrayValues[0][1]
              }
              else {
                  arrayValues[0][1] = parseInt(element[1]) + arrayValues[0][1]
              }
          }

          if (arrayValues[1].length < 10 && element[0] !== 'US') {
              arrayLabels[1].push(element[0])
              arrayValues[1].push(parseInt(element[1]))
          }


      } else {
          arrayLabels[0].push(element[0] ?? '')
          arrayValues[0].push(parseInt(element[1]))
      }
  });
  const labels = arrayLabels[0]
  const naPresent = labels.indexOf('N/A')
  if (naPresent === -1 && !excludeNA)  {
      const emptyPresent = labels.indexOf('');
      if(emptyPresent === -1){
          if(parseInt(totalRef) - parseInt(totalFromQuery) > 0){
              arrayLabels[0].push('N/A')
              arrayValues[0].push(parseInt(totalRef) - parseInt(totalFromQuery))
          }
      } else {
          arrayLabels[0][emptyPresent] = 'N/A'
          arrayValues[0][emptyPresent] = parseInt(arrayValues[0][emptyPresent]) + (parseInt(totalRef) - parseInt(totalFromQuery))
          arrayLabels[0].push(arrayLabels[0].splice(emptyPresent, 1)[0])
          arrayValues[0].push(arrayValues[0].splice(emptyPresent, 1)[0])
      }
  }



  dataTable[0] = arrayLabels[0].map((item, index) => {
      return <tr>
          <td className="cell-label grouped-cell" ><SquareLegend indexColor={index} keyStorage={''}/>&nbsp;{item}</td>
          <td className="cell-value grouped-cell" ><FormattedValue type={typeValues === 'money' ? "money" : "absolut"} value={Number(arrayValues[0][index])} /></td>
          {noPErcent ? <></>:<td className="cell-value grouped-cell" ><FormattedValue type="percent" value={Number((arrayValues[0][index] / totalRef) * 100)} /></td>}
          <td className="cell-value grouped-cell" ><FormattedValue type="decimal" value={Number(extraFields[index])} /></td>
      </tr>
  })
  dataTable[1] = arrayLabels[1].map((item, index) => {
      return <tr>
          <td className="cell-label grouped-cell" ><SquareLegend indexColor={index} keyStorage={''}/>&nbsp;{item}</td>
          <td className="cell-value grouped-cell" ><FormattedValue type={typeValues === 'money' ? "money" : "absolut"} value={Number(arrayValues[1][index])} /></td>
          <td className="cell-value grouped-cell" ><FormattedValue type="decimal" value={Number(
              (arrayValues[1][index] / totalRef) * 100)} /></td>
          <td className="cell-value grouped-cell" ><FormattedValue type="decimal" value={Number(extraFields[index])} /></td>
      </tr>

})

  return [arrayLabels, arrayValues, dataTable]

}

export const teamMembersValues = (values, totalRef) => {
    let arrayLabels = [[], []]
    let arrayValues = [[], [], []]
    let dataTable = [[], []]
    values.forEach(element => {
        let dateItem = new Date(element[0])
        arrayLabels[0].push(dateUtilities.monthsNames[dateItem.getUTCMonth()] + ' ' + dateItem.getUTCFullYear())
        arrayValues[0].push(parseFloat(element[2]))
        arrayValues[1].push(parseFloat(element[3]))
        arrayValues[2].push(parseFloat(element[4]))
    });
    dataTable[0] = arrayLabels[0].map((item, index) => {
        return <tr>
            <td className="cell-label" >{item}</td>
            <td className="cell-value" ><FormattedValue type="money" value={Number(arrayValues[1][index])} /></td>
            <td className="cell-value" ><FormattedValue type="absolut" value={Number(arrayValues[2][index])} /></td>
            <td className="cell-value" ><FormattedValue type="money" value={Number(arrayValues[0][index])} /></td>
        </tr>
    })

    return dataTable

}

export const costAttributionToCohort = (totalRaw, cohortsValues) => {
    const total = (totalRaw / 100)
    cohortsValues.forEach(element => {
        switch (element[0]) {
            case 'Reseller':
                element[1] = (total * 0.3477)
                break;
            case 'Personal':
                element[1] = (total * 0.3324)
                break;
            case 'Professional':
                element[1] = (total * 0.2578)
                break;
            case 'Business':
                element[1] = (total * 0.0539)
                break;

            default:
                break;
        }
    });
    return cohortsValues
}


export const groupBy = (arr, index) => {
    return arr.reduce(function (memo, x) {
        if (!memo[x[index]]) {
            memo[x[index]] = [];
        }
        memo[x[index]].push(x);
        return memo;
    }, {})
}

